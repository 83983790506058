import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';


const Layout = ({ children, notify }) => {
    const childrenWithProps = Children.map(children, (child) => {
        return cloneElement(child, { notify });
    });

    return (
        <>
            {childrenWithProps}
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    notify: PropTypes.func,
};

export default Layout;
