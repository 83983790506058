import { useEffect, useState, useContext } from 'react';
import Homepage from '../../templates/homepage/Homepage';
import LandingPage from '../landingPage/LandingPage';
import BackgroundPage from './BackgroundPage';
import Cookie from 'js-cookie';
import { UserContext } from '@mdc/ui';

const ConditionalPage = () => {
    const [isCookieRead, setIsCookieRead] = useState(false);
    const [cookieValue, setCookieValue] = useState();
    const userContext = useContext(UserContext);

    useEffect(() => {
        const cookieValue = Cookie.get('loginComercial');
        setCookieValue(cookieValue);
        setIsCookieRead(true);
    });

    if (!isCookieRead || userContext?.state === 'pending') {
        return <BackgroundPage />;
    }

    if (cookieValue === 'true' && (userContext?.state === 'logged in')) {
        return <Homepage />;
    }

    return <LandingPage />;
};

export default ConditionalPage;
