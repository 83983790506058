import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../components/layouts/Layout';
import Seo from '../../components/seo';
import HomepageComponent from '../../components/homepage/Homepage';
import { NotificationBarConsumer, DropZoneConsumer } from '@mdc/ui';
import { ThemeProvider } from '@mdc/services';

import './Homepage.scss';


const Homepage = () => {

    const homeImageAssets = useStaticQuery(graphql`
        query homeImageAssets {
            allFile(filter: { sourceInstanceName: { eq: "cityBackgrounds" }, extension: { eq: "jpg" }, relativePath: { regex: "/^backgrounds/" }, name: { regex: "/_001|_002/"} }) {
                edges {
                    node {
                        publicURL
                        name
                        childImageSharp {
                            fluid(quality: 100, maxWidth: 2560) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            unknownThreats: imageSharp(resize: {originalName: {eq: "home-unknown-threats.png"}}) {
                fluid(quality: 100, maxWidth: 320) {
                    ...GatsbyImageSharpFluid
                }
            }
            vulnerability: imageSharp(resize: {originalName: {eq: "home-vulnerability.png"}}) {
                fluid(quality: 100, maxWidth: 320) {
                    ...GatsbyImageSharpFluid
                }
            }
            threat: imageSharp(resize: {originalName: {eq: "home-threat.png"}}) {
                fluid(quality: 100, maxWidth: 320) {
                    ...GatsbyImageSharpFluid
                }
            }
            multiscan: imageSharp(resize: {originalName: {eq: "home-multiscan.png"}}) {
                fluid(quality: 100, maxWidth: 692) {
                    ...GatsbyImageSharpFluid
                }
            }
            fileInfo: imageSharp(resize: {originalName: {eq: "home-file-info.png"}}) {
                fluid(quality: 100, maxWidth: 692) {
                    ...GatsbyImageSharpFluid
                }
            }
            scanHistory: imageSharp(resize: {originalName: {eq: "home-scan-history.png"}}) {
                fluid(quality: 100, maxWidth: 692) {
                    ...GatsbyImageSharpFluid
                }
            }
            opswatLogo: file(relativePath: {eq: "opswat.svg"}) {
                publicURL
            }
        }
    `);

    return (
        <ThemeProvider>
            <NotificationBarConsumer>
                <Layout>
                    <Seo title="Advanced threat prevention and detection" theme="city" />
                    <DropZoneConsumer>
                        <HomepageComponent homeImageAssets={homeImageAssets} />
                    </DropZoneConsumer>
                </Layout>
            </NotificationBarConsumer>
        </ThemeProvider>
    );
};

export default Homepage;
