import PropTypes from 'prop-types';
import './Card.scss';
import { Button } from 'react-bootstrap';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Card = ({ className, iconClass, title, description, buttonText, imageClass, subtitle, buttonHref }) => {
    return (
        <div className='landingCard'>
            <div className={`card ${className}`}>
                <div className={`card-image ${imageClass}`}></div>
                <div className='card-content'>
                    {iconClass && <img className={`card-icon ${iconClass}`} alt='Icon' />}
                    {subtitle && <div className='card-subtitle'>{subtitle}</div>}
                    {title && <div className='card-title'>{title}</div>}
                    {description && <div className='card-description'>{description}</div>}
                    {buttonText && buttonHref && (
                        <OutboundLink href={buttonHref} target='_blank'>
                            <Button className='card-button'>{buttonText}</Button>
                        </OutboundLink>
                    )}
                </div>
                <div className='card-footer'></div>
            </div>
        </div>
    );
};

Card.propTypes = {
    className: PropTypes.string,
    subtitle: PropTypes.string,
    iconClass: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    imageClass: PropTypes.string,
    buttonHref: PropTypes.string,
};

export default Card;
